import { PropsWithChildren } from "react";

type ButtonProps = {
  onClick?: () => void
}

const Button = (props: PropsWithChildren<ButtonProps>) => {
  return <button onClick={props.onClick} className="rounded bg-indigo-600 mx-2 my-2 px-2 py-1 text-m font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
    {props.children}
  </button>;
};

export default Button;