import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useState } from "react";

function UserSettingJwt() {
  const {getAccessTokenSilently} = useAuth0();  
  const [token, setToken] = useState("");

    const updateToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    }
    
    updateToken();

    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-900">JSON Web Token</dt>
      <dd className="text-sm text-gray-700 sm:col-span-3 sm:mt-0 flex flex-col">
        {token}
      </dd>
    </div>
    )
}


export default withAuthenticationRequired(UserSettingJwt);