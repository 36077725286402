import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import UserSettingEmail from "../components/user-setting-email";
import UserSettingJwt from "../components/user-setting-jwt";

function Settings() {
  const { user } = useAuth0();

  return (
    <div>
      <h1 className='text-4xl mb-4 pl-2 font-semibold'>Settings</h1>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Account</h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Your account details and linked account connections.</p>
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Nickname</dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-3 sm:mt-0">{user?.nickname}</dd>
            </div>
          <UserSettingEmail />
          {process.env["NODE_ENV"] == "development" && <UserSettingJwt />}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Settings);
