import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from '../../auth/components/login-button';

function LoginIfUnauthenticated() {
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return (<LoginButton></LoginButton>)
  }
}

function Home() {
  

  return (
    <div className='flex flex-col w-full h-full'>
      <div className="flex-grow"></div>
      <div className='text-6xl flex-grow-0 text-center'>Microjot</div>
      <div className="h-5"></div>
      <div className="mt-2 text-center">
        { LoginIfUnauthenticated() }
      </div>
      <div className="flex-grow"></div>
    </div>
  );
}

export default Home;