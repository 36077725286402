import { useAuth0 } from "@auth0/auth0-react";
import Loading from './Loading';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {Link} from 'react-router-dom'
import Home from './features/home/pages/home'
import Settings from './features/settings/pages/settings'
import LogoutButton from './features/auth/components/logout-button'
import { Cog6ToothIcon, HomeIcon } from '@heroicons/react/24/outline';

function App() {
  const { isLoading, error, isAuthenticated } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  
  if (isLoading) {
    return <Loading />;
  }

  return (
      <BrowserRouter>
        <div className='min-h-screen bg-slate-100 flex flex-row'>
          <nav className='flex flex-col bg-slate-200 text-gray-500 p-3 w-20 border-r-2 border-slate-300'>
            <ul className='flex flex-col gap-y-2'>
              <li><Link title="Home" to="/"><HomeIcon title="Home" className="hover:text-blue-600"></HomeIcon></Link></li>
            </ul>
            <ul className='flex flex-col mt-auto gap-y-3'>
              {isAuthenticated && (<li><LogoutButton></LogoutButton></li>)}
              {isAuthenticated && (<li><Link title="Settings" to="/settings"><Cog6ToothIcon title="Settings" className="hover:text-blue-600"/></Link></li>)}
            </ul>
          </nav>
          <div className='p-4 py-2 w-full h-screen overflow-y-scroll'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
  );
}

export default App;
