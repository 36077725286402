import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import InputTextboxEditable from "../../../components/input-textbox-editable";

function UserSettingEmail() {

    const saveChangeToEmail = async () => {
      return
    }

    const {user} = useAuth0();
    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900 flex items-center">Email address</dt>
        <dd className="text-sm text-gray-700 sm:col-span-3 sm:mt-0 flex flex-col align-top">
          <div className="flex flex-row align-top">
            {
              user?.email ?
              (<div className="flex-grow-0 pr-2"><InputTextboxEditable onSave={saveChangeToEmail}>{user.email}</InputTextboxEditable></div>)
              :
              (<div className="flex-grow-0 pr-2 italic">No email available.</div>) 
            }
            <div className="flex-grow" />
          </div>
        </dd>
      </div>
    )
}


export default withAuthenticationRequired(UserSettingEmail);