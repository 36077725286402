import { PropsWithChildren, useState } from "react";
import Button from "./button";

type InputTextBoxEditableProps = {
  children: string,
  onSave: (text: string) => Promise<void>
}

const InputTextboxEditable = (props: PropsWithChildren<InputTextBoxEditableProps>) => {
  const initialText = props.children;
  const [text, setText] = useState(props.children);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  if (isSaving) {
    return <div>Saving...</div>
  }

  const cancel = () => {
    setIsEditing(false);
    setText(initialText);
  }

  const save = async () => {
    try {
      setIsSaving(true);
      
      await props.onSave(text);

      setIsEditing(false);
      setIsSaving(false);
    } catch (err) {
      setText(initialText);
      setIsSaving(false);
      setIsEditing(false);
    }
  }

  if (isEditing) {
    return (<div className="flex flex-row">
        <input type="email" name="email" placeholder="you@example.com" 
        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={text} onChange={e => setText(e.target.value)}></input>
        <Button onClick={() => {save()}}>Save</Button>
        <Button onClick={() => {cancel()}}>Cancel</Button>
      </div>
    )
  }
  
  return <div className="flex flex-row items-center"><div>{text}</div><Button onClick={() => {setIsEditing(true)}}>Change</Button></div>
};

export default InputTextboxEditable;